// Dealer Setup
export const dealeruserCreate = '/user/dealer-user-create'
export const dealerSetupList = '/fertilizer/config/dealer-setup/list'
export const dealerSetupStore = '/fertilizer/config/dealer-setup/store'
export const dealerSetupUpdate = '/fertilizer/config/dealer-setup/update'
export const dealerSetupToggleStatus = '/fertilizer/config/dealer-setup/toggle-status'
export const dealerSetupDestroy = '/fertilizer/config/dealer-setup/destroy'

// Dealer Requisition
const dealerPanelRequisition = 'dealer-management/requisitions/'
export const requisitionList = dealerPanelRequisition + 'list'
export const requisitionStore = dealerPanelRequisition + 'store'
export const requisitionUpdate = dealerPanelRequisition + 'update'
export const requisitionStatus = dealerPanelRequisition + 'toggle-status'

// Dealer Sales Manage
const dealerPanelSalesManage = 'dealer-management/sells/'
export const saleEntryList = dealerPanelSalesManage + 'list'

// Dealer Report
export const dealerReportList = 'dealer-management/dealer-report'
export const fertilizerAllotmentReport = 'dealer-panel/reports/fertilizer-allotment-report-admin'
export const fertilizerRequisitionReport = '/dealer-panel/reports/fertilizer-requisition-report-admin'
export const fertilizerSalesReport = 'dealer-management/fertilizer-sales-report'
export const dealerManagementdashboard = 'dealer-management/dashboard'
