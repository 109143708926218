<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dealer_management.dealer_info') }}</h4>
        </template>
      <template v-slot:body>
            <b-row>
              <b-col class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('fertilizerConfig.name_of_region')"
                    label-for="name_of_region"
                    >
                    <v-select name="name_of_region"
                      v-model="search.region_name"
                      label="text"
                      :options= officeList
                    />
                  </b-form-group>
                </b-col>
                <b-col class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_division.division')"
                    label-for="division_name"
                    >
                    <v-select name="godown_Info"
                        v-model="search.division_name"
                        label="text"
                        :options= divisionList
                        @input="getDistrictList(search.division_name.value)"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <v-select name="godown_Info"
                        v-model="search.district_name"
                        label="text"
                        :options= districtList
                        @input="getUpazilaList(search.district_name.value)"
                    />
                  </b-form-group>
                </b-col>
                <b-col  class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <v-select name="godown_Info"
                        v-model="search.upazilla_name"
                        label="text"
                        :options= upazilaList
                    />
                  </b-form-group>
                </b-col>
                <b-col class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('fertilizerConfig.registration_no')"
                    label-for="registration_no"
                    >
                    <b-form-input
                      id="registration_no"
                      v-model="search.registration_no"
                      placeholder=""
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="col-lg-6">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('fertilizerConfig.dealer_name')"
                    label-for="name"
                    >
                    <b-form-input
                      id="name"
                      v-model="search.name"
                      placeholder=""
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dealer_management.dealer_info_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(heading)="data">
                      {{ data.item.heading }}
                    </template>
                    <template v-slot:cell(left_logo)="data">
                      <b-img width='100px' :src="warehouseServiceBaseUrl+'storage/'+data.item.left_logo" fluid alt="Left Logo"></b-img>
                    </template>
                    <template v-slot:cell(right_logo)="data">
                      <b-img width='100px' :src="warehouseServiceBaseUrl+'storage/'+data.item.right_logo" fluid alt="Right Logo"></b-img>
                    </template>
                    <template v-slot:cell(category)="data">
                     {{ getCategory(data.item.category) }}
                    </template>
                    <template v-slot:cell(address)="data">
                      {{ data.item.address }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ data.item.org_name }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                            <i class="ri-ball-pen-fill"></i>
                        </a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                            <i class="fas fa-toggle-on"></i>
                        </a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                            <i class="fa fa-toggle-off"></i>
                        </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './FormV'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerSetupList, dealerSetupToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        pre_division_id: 0,
        pre_district_id: 0,
        pre_upazilla_id: 0,
        pre_region_id: 0,
        registration_no: '',
        division_name: null,
        district_name: null,
        upazilla_name: null,
        name: ''
      },
      upazilaList: [],
      districtList: [],
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('fertilizerConfig.dealer_setup') : this.$t('fertilizerConfig.dealer_setup_modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('fertilizerConfig.registration_no'), class: 'text-center' },
          { label: this.$t('fertilizerConfig.dealer_name'), class: 'text-center' },
          { label: this.$t('fertilizerConfig.dealer_category'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'registration_no_bn' },
          { key: 'name_bn' },
          { key: 'category' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'registration_no' },
          { key: 'name' },
          { key: 'category' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  created () {
    this.loadData()
  },
    watch: {
        'search.pre_division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.pre_district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        }
    },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(seedFertilizerServiceBaseUrl, dealerSetupToggleStatus, item)
    },
    loadData () {
        this.search.pre_division_id = 0
        this.search.pre_district_id = 0
        this.search.pre_upazilla_id = 0
        this.search.pre_region_id = 0
        if (this.search.division_name) {
          this.search.pre_division_id = this.search.division_name.value
        }
        if (this.search.district_name) {
          this.search.pre_district_id = this.search.district_name.value
        }
        if (this.search.upazilla_name) {
          this.search.pre_upazilla_id = this.search.upazilla_name.value
        }
        if (this.search.region_name) {
          this.search.pre_region_id = this.search.region_name.value
        }
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(seedFertilizerServiceBaseUrl, dealerSetupList, params).then(response => {
            if (response.success) {
            this.$store.dispatch('setList', response.data.data.filter(item => item.category === 2))
            this.paginationData(response.data)
            }
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCategory (category) {
      if (category === 1) {
          return this.$i18n.locale === 'bn' ? 'বীজ' : 'Seeds'
      } else {
        return this.$i18n.locale === 'bn' ? 'সার' : 'Fertilizer'
      }
    },
    getDistrictList (divisionId) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            this.districtList = districtList.filter(district => district.division_id === divisionId)
        }
    },
    getUpazilaList (districtId) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
            this.upazilaList = upazilaList.filter(upazila => upazila.district_id === districtId)
        }
    }
  }
}
</script>
