<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)">
                    <b-row class="mt-2">
                        <b-col lg="6"  sm="12">
                           <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Year Of Registration" vid="registration_year" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="registration_year"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.registration_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.registration_year"
                                    :options="yearOfRegistration"
                                    id="registration_year"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Dealer Category" vid="category" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="category"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.dealer_category')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="dealerSetup.category"
                                :options="dealerCategory"
                                id="category"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6"  sm="12">
                        <ValidationProvider name="Dealer Code" vid="dealer_code" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.dealer_code')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="text"
                                    id="dealer_code"
                                    v-model="dealerSetup.dealer_code"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                    </b-col>
                    <b-col lg="6"  sm="12">
                        <ValidationProvider name="Dealer Registration No (En)" vid="registration_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.dealer_registration_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="text"
                                    id="registration_no"
                                    v-model="dealerSetup.registration_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                    </b-col>
                    <b-col lg="6"  sm="12">
                        <ValidationProvider name="Dealer Registration No (Bn)" vid="registration_no_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.dealer_registration_no_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="text"
                                    id="registration_no_bn"
                                    v-model="dealerSetup.registration_no_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                    </b-col>
                    <!-- <b-col lg="6"  sm="12">
                        <ValidationProvider name="Registration No" vid="registration_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.registration_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="text"
                                    id="registration_no"
                                    v-model="dealerSetup.registration_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                    </b-col> -->
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Registration Date" vid="registration_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="registration_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.registration_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="dealerSetup.registration_date"
                                    placeholder="Select Date"
                                    id="registration_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col  lg="6"  sm="12">
                            <ValidationProvider name="Licence Number" vid="licence_number" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="licence_number"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.licence_number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="licence_number"
                                    v-model="dealerSetup.licence_number"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                     <b-row>
                         <b-col>
                            <ValidationProvider name="Licence Issue Date" vid="licence_issue_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="licence_issue_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.licence_issue_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="dealerSetup.licence_issue_date"
                                    placeholder="Select Date"
                                    id="licence_issue_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="Licence Expire Date" vid="licence_expire_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="licence_expire_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.licence_expire_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="dealerSetup.licence_expire_date"
                                    placeholder="Select Date"
                                    id="licence_expire_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <ValidationProvider name="Dealer Name (En)" vid="name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.dealer_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name"
                                    v-model="dealerSetup.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Dealer Name (Bn)" vid="name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.dealer_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name"
                                    v-model="dealerSetup.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Trade Name (En)" vid="trade_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="trade_name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.trade_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="trade_name"
                                    v-model="dealerSetup.trade_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Trade name (Bn)" vid="trade_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="trade_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.trade_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="heading_bn"
                                    v-model="dealerSetup.trade_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="father_name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.father_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="father_name"
                                    v-model="dealerSetup.father_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col lg="6"  sm="12">
                            <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="father_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.father_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="father_name_bn"
                                    v-model="dealerSetup.father_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Mother Name (En)" vid="mother_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mother_name"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.mother_name_en')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-input
                                    id="mother_name"
                                    v-model="dealerSetup.mother_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mother_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.mother_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="heading_bn"
                                    v-model="dealerSetup.mother_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="NID" vid="nid" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="nid"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.nid')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="nid"
                                    v-model="dealerSetup.nid"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Tin No" vid="tin_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="tin_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="tin_no"
                                    v-model="dealerSetup.tin_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Bin No" vid="bin_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="bin_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.bin_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="bin_no"
                                    v-model="dealerSetup.bin_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.contact_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="tin_no"
                                    v-model="dealerSetup.contact_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="6"  sm="12" v-if="!id"> -->
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Email" vid="email" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="email"
                                    id="email"
                                    v-model="dealerSetup.email"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Sec Deposit Amount" vid="sec_deposit_amount" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="sec_deposit_amount"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.sec_deposit_amount')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="sec_deposit_amount"
                                    v-model="dealerSetup.sec_deposit_amount"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Upload Pay Order (Attachment)" vid="pay_order_attachment" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="left_logo"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.upload_pay_order')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-file
                                    id="upload_pay_order"
                                    v-on:change="onOrderLogoChange"
                                    v-model="dealerSetup.pay_order_attachment"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Photo" vid="photo" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="photo"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('fertilizerConfig.photo')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-file
                                    id="photo"
                                    v-on:change="onPhotoLogoChange"
                                    v-model="dealerSetup.photo"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Security Deposit Date" vid="deposit_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="deposit_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.security_deposit_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="dealerSetup.deposit_date"
                                    placeholder="Select Date"
                                    id="deposit_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Security Deposit Expiration Date" vid="expire_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="expire_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.security_deposit_expiration_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="dealerSetup.expire_date"
                                    placeholder="Select Date"
                                    id="expire_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Warehouse Details(En)" vid="warehouse_details" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="warehouse_details"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.warehouse_details_en')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="warehouse_details"
                                    v-model="dealerSetup.warehouse_details"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                             </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Warehouse Details (Bn)" vid="warehouse_details_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('warehouse_config.address_bn')"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.warehouse_details_bn')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="address_bn"
                                    v-model="dealerSetup.warehouse_details_bn"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                         <b-col lg="6"  sm="12">
                            <ValidationProvider name="Sales Center Details(En)" vid="sales_center_details" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="sales_center_details"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.sales_center_details_en')}}<span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="sales_center_details"
                                    v-model="dealerSetup.sales_center_details"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                             </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Sales Center Details (Bn)" vid="sales_center_details_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('warehouse_config.address_bn')"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.sales_center_details_bn')}}<span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="sales_center_details_bn"
                                    v-model="dealerSetup.sales_center_details_bn"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <template>
                        <div style="font-size:18px">
                            <h5 class="text-dark"> {{ $t('fertilizerConfig.present_business_address') }}</h5>
                        </div>
                    </template>
                    <b-row class="mt-2">
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Division" vid="pre_division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pre_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.name_of_division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="dealerSetup.pre_division_id"
                                :options="divisionPreList"
                                id="pre_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6"  sm="12">
                        <ValidationProvider name="Region" vid="pre_region_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pre_region_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.name_of_region')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="dealerSetup.pre_region_id"
                                id="pre_region_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :options="officeList"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="District" vid="pre_district_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.pre_district_id"
                                    :options="districtList"
                                    id="pre_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Upazilla" vid="pre_upazilla_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_upazilla_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_upazilla')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.pre_upazilla_id"
                                    :options="upazilaList"
                                    id="pre_upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Union" vid="pre_union_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_union')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.pre_union_id"
                                    :options="unionList"
                                    id="pre_union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Present/Business Address(En)" vid="pre_address" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_address"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.address_details_en')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="pre_address"
                                    v-model="dealerSetup.pre_address"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                             </ValidationProvider>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Present/Business Address(Bn)" vid="pre_address_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.address_details_bn')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="pre_address_bn"
                                    v-model="dealerSetup.pre_address_bn"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                        </b-col>
                    </b-row>
                    <template>
                        <div style="font-size:18px">
                            <h5 class="text-dark"> {{ $t('fertilizerConfig.permanent_address') }}</h5>
                        </div>
                    </template>
                    <b-row class="mt-2">
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="per_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.name_of_division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="dealerSetup.per_division_id"
                                :options="divisionPreList"
                                id="per_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6"  sm="12">
                        <ValidationProvider name="Region" vid="per_region_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="per_region_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.name_of_region')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="dealerSetup.per_region_id"
                                id="per_region_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :options="officeList"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="per_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.per_district_id"
                                    :options="districtPerList"
                                    id="per_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Upazilla" vid="per_upazilla_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="per_upazilla_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_upazilla')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.per_upazilla_id"
                                    :options="upazilaPerList"
                                    id="per_upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Union" vid="per_union_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="per_union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_union')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="dealerSetup.per_union_id"
                                    :options="unionPerList"
                                    id="per_union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Permanent Address(En)" vid="per_address" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="per_address"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.address_details_en')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="per_address"
                                    v-model="dealerSetup.per_address"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                             </ValidationProvider>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Permanent Address(Bn)" vid="per_address_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="per_address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerConfig.address_details_bn')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-textarea
                                    id="per_address_bn"
                                    v-model="dealerSetup.per_address_bn"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                             </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                        </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { dealerSetupStore, dealerSetupUpdate, dealeruserCreate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getDealerSetupData()
      this.dealerSetup = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#registration_date', {})
    flatpickr('#deposit_date', {})
    flatpickr('#expire_date', {})
    flatpickr('#licence_issue_date', {})
    flatpickr('#licence_expire_date', {})
  },
  data () {
    return {
      test: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      dealerSetup: {
        id: '',
        org_id: 0,
        registration_year: 0,
        category: 0,
        dealer_code: '',
        registration_no: '',
        registration_no_bn: '',
        registration_date: '',
        name: '',
        name_bn: '',
        trade_name: '',
        trade_name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        nid: '',
        tin_no: '',
        bin_no: '',
        email: '',
        contact_no: '',
        sec_deposit_amount: '',
        pay_order_attachment: [],
        deposit_date: '',
        expire_date: '',
        photo: [],
        warehouse_details: '',
        warehouse_details_bn: '',
        sales_center_details: '',
        sales_center_details_bn: '',
        pre_division_id: 0,
        pre_region_id: 0,
        pre_district_id: 0,
        pre_upazilla_id: 0,
        pre_union_id: 0,
        pre_address: '',
        pre_address_bn: '',
        per_division_id: 0,
        per_region_id: 0,
        per_district_id: 0,
        per_upazilla_id: 0,
        per_union_id: 0,
        per_address: '',
        per_address_bn: ''
      },
      pay_order_attachment: [],
      photo: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      districtPerList: [],
      upazilaPerList: [],
      unionPerList: [],
      officeList: [],
      dealerCategory: [
        { text: (this.$i18n.locale === 'bn') ? 'সার' : 'Fertilizer', value: 2 }
      ]
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionPreList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    divisionPerList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    yearOfRegistration: function () {
      const fiscalyear = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  watch: {
    'dealerSetup.pre_division_id': function (newVal, oldVal) {
      this.districtList = this.getPreDistrictList(newVal)
    },
    'dealerSetup.pre_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getPreUpazilaList(newVal)
    },
    'dealerSetup.pre_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getPreUnionList(newVal)
    },
    'dealerSetup.per_division_id': function (newVal, oldVal) {
      this.districtPerList = this.getPerDistrictList(newVal)
    },
    'dealerSetup.per_district_id': function (newVal, oldVal) {
      this.upazilaPerList = this.getPerUpazilaList(newVal)
    },
    'dealerSetup.per_upazilla_id': function (newVal, oldVal) {
      this.unionPerList = this.getPerUnionList(newVal)
    },
    'dealerSetup.org_id': function (newVal, oldVal) {
       this.officeList = this.getOfficeList(newVal)
    }
  },
  methods: {
    getOfficeList (orgId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
        if (orgId) {
            return officeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return officeList
    },
    onOrderLogoChange (e) {
      this.pay_order_attachment = e.target.files[0]
    },
    onPhotoLogoChange (e) {
      this.photo = e.target.files[0]
    },
    getDealerSetupData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.dealerSetup).map(key => {
        if (key === 'pay_order_attachment') {
          formData.append(key, this.pay_order_attachment)
        }
        if (key === 'photo') {
          formData.append(key, this.photo)
        } else {
          formData.append(key, this.dealerSetup[key])
        }
      })

      if (this.dealerSetup.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, `${dealerSetupUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(authServiceBaseUrl, dealeruserCreate, formData, config)
        if (result.success) {
            result = await RestApi.postData(seedFertilizerServiceBaseUrl, dealerSetupStore, formData, config)
        }
      }

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getPreDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getPreUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getPreUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getPerDistrictList (divisionId = null) {
      const districtPerList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtPerList.filter(district => district.division_id === divisionId)
      }

      return districtPerList
    },
    getPerUpazilaList (districtId = null) {
      const upazilaPerList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaPerList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaPerList
    },
    getPerUnionList (upazilaId = null) {
      const unionPerList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionPerList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionPerList
    }
  }
}
</script>
